import { Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar/schoolSideBar";
import ProfileNav from "./ProfileNav"


const SchoolSetttingsContainer = (props) => {
    const { handleChange, handleSubmit, accountDetails, errors } = props; // Accept errors prop

    return (
        <Fragment>
            <div className='wrapper sidebar-mini layout-fixed'>
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Settings</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                        <li className="breadcrumb-item active">settings</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="row mb-2">

                            <ProfileNav />
                            </div>
                            <div className="row mb-2">
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm-12 card">
                                    <div className="card-header">
                                        <div className="row mb-2">
                                            <div className="col-sm-6 profile-panel-padding">
                                                <h5>Account</h5>
                                            </div>
                                            <div className="col-sm-6">
                                                <button className="btn btn-success float-right" onClick={handleSubmit} data-toggle="modal" data-target="#medicalModal">
                                                    Add account details
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body padding-bottom">
                                        <form>
                                            <div className="row mb-2 padding_top">
                                                <div className="col-sm-4">
                                                    <label htmlFor="customer">Bank name</label>
                                                    <input 
                                                        type="text" 
                                                        name="bankName" 
                                                        value={accountDetails?.bankName} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.bankName ? 'is-invalid' : ''}`} 
                                                        placeholder="Bank name"
                                                    />
                                                    {errors?.bankName && <span className="invalid-feedback">{errors.bankName}</span>}
                                                </div>
                                                <div className="col-sm-4">
                                                    <label htmlFor="customer">Account name</label>
                                                    <input 
                                                        type="text" 
                                                        name="accountName" 
                                                        value={accountDetails?.accountName} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.accountName ? 'is-invalid' : ''}`} 
                                                        placeholder="Account name"
                                                    />
                                                    {errors?.accountName && <span className="invalid-feedback">{errors.accountName}</span>}
                                                </div>
                                                <div className="col-sm-4">
                                                    <label htmlFor="customer">Account number</label>
                                                    <input 
                                                        type="text" 
                                                        name="accountNumber" 
                                                        value={accountDetails?.accountNumber} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.accountNumber ? 'is-invalid' : ''}`} 
                                                        placeholder="Account number"
                                                    />
                                                    {errors?.accountNumber && <span className="invalid-feedback">{errors.accountNumber}</span>}
                                                </div> 
                                            </div>         

                                            <div className="row mb-2 padding_top">
                                                <div className="col-sm-4">
                                                    <label htmlFor="customer">Branch name</label>
                                                    <input 
                                                        type="text" 
                                                        name="branchName" 
                                                        value={accountDetails?.branchName} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.branchName ? 'is-invalid' : ''}`} 
                                                        placeholder="Branch name"
                                                    />
                                                    {errors?.branchName && <span className="invalid-feedback">{errors.branchName}</span>}
                                                </div> 
                                                <div className="col-sm-4">
                                                    <label htmlFor="customer">Swift code</label>
                                                    <input 
                                                        type="text" 
                                                        name="swiftCode" 
                                                        value={accountDetails?.swiftCode} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.swiftCode ? 'is-invalid' : ''}`} 
                                                        placeholder="Swift code"
                                                    />
                                                    {errors?.swiftCode && <span className="invalid-feedback">{errors.swiftCode}</span>}
                                                </div> 
                                                <div className="col-sm-4">
                                                    <label htmlFor="customer">Admission fee</label>
                                                    <input 
                                                        type="number" 
                                                        name="admissionFee" 
                                                        value={accountDetails?.admissionFee}  
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.admissionFee ? 'is-invalid' : ''}`} 
                                                        placeholder="Admission fee"
                                                    />
                                                    {errors?.admissionFee && <span className="invalid-feedback">{errors.admissionFee}</span>}
                                                </div> 
                                            </div>   
                                        </form>       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <Footer />
            </div>    
        </Fragment>
    );
}

export default SchoolSetttingsContainer;
