import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoginForm from "../../containers/LoginForm/LoginForm";
import { loginUser } from "../../actions/userActions";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
    }

    componentDidUpdate(prevProps) {
        const { userType, history } = this.props;
        if (prevProps.userType !== userType && userType) {
            const path = this.handleRedirection(userType);
            history.push(path);
        }
    }

    handleRedirection = (userType) => {
        let path = '/home'; // Default path
        if (userType === 'school') {
            path = '/school-portal';
        } else if (userType === 'sch-admin') {
            path = '/school-portal';
        }
        return path;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { signIn } = this.props;
        const { email, password } = this.state;
        const user_data = { email, password };
        signIn(user_data);
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <LoginForm
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
            />
        );
    }
}

Login.propTypes = {
    signIn: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    userType: PropTypes.string // Add this to check the type of user
};

const mapStateToProps = (state) => ({
    userType: state.user.userType, // Ensure you're getting userType correctly
});

export default connect(mapStateToProps, { signIn: loginUser })(Login);
