import {Fragment} from "react";
import { Link } from "react-router-dom";
import SchoolSidebar from "../Sidebar/schoolSideBar";
import BarChart from "../Charts/bar";
import SchLineChart from "../Charts/SchLineChart";
import Footer from "../Footer";
import Header from "../../components/Header"

const   SchoolDashboard =(props)=>{
    const { totalApplications, totalAdmissions, totalRejections, balance} = props
    return(
    <Fragment>
        <div className='wrapper  sidebar-mini layout-fixed'>
            <Header/>
            <SchoolSidebar currentUser={'currentUser'}/>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">School Dashboard</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                                    <li className="breadcrumb-item active">School Dashboard</li>
                                </ol>
                            </div>
                        </div>
                        <div className="row mb-2">
                        <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Received</span>
                                    
                                    <strong>{ totalApplications }</strong>
                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                <span className="info-box-icon bg-info elevation-1"><i className="fab fa-adn"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Admisions</span>
                                    <span className="info-box-number">

                                        {totalAdmissions}

                                    </span>
                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                <span className="info-box-icon bg-danger elevation-1"><i className="fa fa-ban"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text"> Rejections</span>
                                    <span className="info-box-number">
                                    {totalRejections}
                                    </span>
                                </div>
                                </div>
                            </div>

                            <div className="clearfix hidden-md-up"></div>

                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                <span className="info-box-icon bg-success elevation-1"><i className="fas fa-money-check"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Revenue (UGX)</span>
                                    <span className="info-box-number">
                                     { balance }
                                       
                                    </span>
                                </div>
                                </div>
                            </div>

                        </div>
                       
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-center text-center">
                                                <h3 className="card-title text-center">Insights</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-4 stat_panel_height stat_panel">
                                                      <BarChart/>
                                            </div>                                        
                                        </div>
                          
                          
                             </div>
                            </div>
                        </div>   

                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-center text-center">
                                                <h3 className="card-title text-center">Monthly Applications Over Time with Projections</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-4 stat_panel_height stat_panel">
                                                      <SchLineChart/>
                                            </div>                                        
                                        </div>
                          
                          
                             </div>
                            </div>
                        </div>                                 
                        
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    </Fragment>
    )
}
export default SchoolDashboard
