import {
    LOGIN_INITIATED,
    LOGIN_SUCCESSFUL,
    LOGIN_ERROR, LOGOUT_USER,
    REGISTRATION_SUCCESSFUL,
    REGISTRATION_ERROR,
    GET_PROFILE_SUCCESSFUL,
} from "../actions/types"

const initialState ={
    loading:false,
    errorMessage:'',
    isUserLoggedIn:false,
    isUserRegistered:false,
    userType:'',
    currentUser:'',
    first_name:'',
    last_name:'',
    email:'',
    gender:'',
    dob:'',
    phone_number:''

}

const userReducer=(state=initialState, action)=>{
    switch (action.type){
        case LOGIN_INITIATED:
            return{
                ...state,
                loading:true
            }
        case LOGIN_SUCCESSFUL:
            return{
                ...state,
                loading: false,
                isUserLoggedIn:true,
                userType: action.userDetails.userType,
                currentUser: action.userDetails.currentUser
            }
        case LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            }
        case LOGOUT_USER:
            return {
                ...state
            }
        case REGISTRATION_SUCCESSFUL:
            return {
                ...state,
                isUserRegistered:true
            }
        case REGISTRATION_ERROR:
            return {
                ...state
            }
        case GET_PROFILE_SUCCESSFUL:
            const {first_name,last_name, email, gender, dob, phone_number} = action.profilePayload
            return{
                ...state,
                first_name,
                last_name,
                email,
                gender,
                dob,
                phone_number
            }
        default:
            
            return state
    }
}
export default userReducer
