import { Component } from "react";
import { connect } from "react-redux";
import { addSchoolAccount, getSchoolAccountDetails } from "../../../actions/institutionActions";
import SchoolSetttingsContainer from "../../../containers/SchoolSettings/SchoolSettings";
import { toast } from "react-toastify";

class SchoolSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankName: '',
            accountName: '',
            accountNumber: '',
            branchName: '',
            swiftCode: '',
            admissionFee: '',
            errors: {}
        };
    }

    componentDidMount() {
        const { getSchoolAccountDetails } = this.props;
        getSchoolAccountDetails();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { accountDetails } = nextProps;
    
        // Check if props have changed and if state is still in its initial state
        if (
            accountDetails &&
            (accountDetails.bankName !== prevState.bankName ||
                accountDetails.accountName !== prevState.accountName ||
                accountDetails.accountNumber !== prevState.accountNumber ||
                accountDetails.branchName !== prevState.branchName ||
                accountDetails.swiftCode !== prevState.swiftCode ||
                accountDetails.admissionFee !== prevState.admissionFee)
        ) {
            return {
                // Only update state if fields are empty, indicating first load
                bankName: prevState.bankName || accountDetails.bankName || '',
                accountName: prevState.accountName || accountDetails.accountName || '',
                accountNumber: prevState.accountNumber || accountDetails.accountNumber || '',
                branchName: prevState.branchName || accountDetails.branchName || '',
                swiftCode: prevState.swiftCode || accountDetails.swiftCode || '',
                admissionFee: prevState.admissionFee || accountDetails.admissionFee || '',
                errors: {} // Clear errors when loading new data
            };
        }
        return null; // No change to state
    }
    

    validateFields = () => {
        const { bankName, accountName, accountNumber, branchName, swiftCode } = this.state;
        const errors = {};
    
        if (!bankName) errors.bankName = "Bank name is required.";
        if (!accountName) errors.accountName = "Account name is required.";
        if (!accountNumber) errors.accountNumber = "Account number is required.";
        if (!branchName) errors.branchName = "Branch name is required.";
        if (!swiftCode) errors.swiftCode = "Swift code is required.";
    
        return errors;
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            [name]: value,
            errors: { ...prevState.errors, [name]: '' }
        }));
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const errors = this.validateFields();

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            toast.error("Please fill in all required fields.");
        } else {
            const { addSchoolAccount } = this.props;
            const schoolData = {
                bank: this.state.bankName,
                account_name: this.state.accountName,
                account_number: this.state.accountNumber,
                branch_name: this.state.branchName,
                swift_code: this.state.swiftCode,
            };
            addSchoolAccount({ school_account: schoolData });
        }
    };

    render() {
        const { bankName, accountName, accountNumber, branchName, swiftCode, admissionFee, errors } = this.state;

        return (
            <SchoolSetttingsContainer
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                accountDetails={{
                    bankName,
                    accountName,
                    accountNumber,
                    branchName,
                    swiftCode,
                    admissionFee
                }}
                errors={errors}
            />
        );
    }
}

const mapStateToProps = state => ({
    accountDetails: state.schools // Ensure this matches your state structure
});

const institutionSettings = connect(mapStateToProps, { addSchoolAccount, getSchoolAccountDetails })(SchoolSettings);
export default institutionSettings;
