import { useEffect } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

const PaymentComponent = ({ config, onSuccess, onError }) => {
    const handleFlutterwavePayment = useFlutterwave(config);

    useEffect(() => {
        if (config) {
            // This will trigger the payment
            handleFlutterwavePayment({
                callback: (response) => {
                    if (response.status === "successful") {
                        onSuccess(response); // Payment was successful
                    } else {
                        onError(response); // Payment failed or was canceled
                    }
                    closePaymentModal(); // Close modal after the callback
                },
                onclose: () => {
                    onError("Payment closed without completion");
                },
            });
        }
    }, [config, handleFlutterwavePayment, onSuccess, onError]);

    return null; // No UI rendered
};

export default PaymentComponent;
