import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import brandLogoWhite from "../../static/images/brandLogoWhite.png";
import { currentUser } from "../../config/constants";

const Sidebar = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    // Utility function to check if the current path matches
    const isActive = (path) => splitLocation[1] === path ? "nav-link active" : "nav-link";

    return (
        <Fragment>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <div className="brand-link">
                    <img src={brandLogoWhite} alt="Mukisa LMS Logo" className="brand-image" style={{ opacity: 0.8 }} />
                    <span className="brand-text font-weight-light"> &nbsp;</span>
                </div>

                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="info">
                            <h5 className="company-name">{currentUser}</h5>
                        </div>
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <Link to="/home" className={isActive("home")}>
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>Dashboard</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open">
                                <a href="#" className={isActive("admin-portal")}>
                                    <i className="nav-icon fab fa-adn"></i>
                                    <p>
                                        Applications
                                        <i className="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    {["kindergarten", "primary", "secondary", "tertiary", "universities"].map((level) => (
                                        <li className="nav-item" key={level}>
                                            <Link to={`/application/${level}`} className="nav-link">
                                                <i className={`nav-icon fa ${level === "tertiary" || level === "universities" ? "fas fa-university" : "fa-child"}`}></i>
                                                <p>
                                                    {level.charAt(0).toUpperCase() + level.slice(1)}{" "}
                                                    {level === "tertiary" ? "Institutions" : level === "universities" ? "" : "Schools"}
                                                    {(level === "tertiary" || level === "university") && (
                                                        <span className="right badge badge-danger">New</span>
                                                    )}
                                                </p>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/myapplications" className={isActive("myapplications")}>
                                    <i className="nav-icon far fa-image"></i>
                                    <p>My Applications</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/myadmissions" className="nav-link">
                                    <i className="nav-icon fab fa-resolving"></i>
                                    <p>Admission Results</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="info">
                        </div>
                    </div>
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <Link to="/profile/general" className={isActive("settings")}>
                                    <i className="nav-icon fas fa-cog"></i>
                                    <p>Settings</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/logout" className={isActive("logout")}>
                                    <i className="nav-icon fas fa-sign-out-alt"></i>
                                    <p>Logout</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </Fragment>
    );
};

export default Sidebar;
