import { Link, useLocation } from "react-router-dom"


const ProfileNav=()=>{
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return(
        <ul className="nav">
        <li className="nav-item">
            <Link className={splitLocation[3] === "general" ? "nav-link active" : "nav-link profile-link"} aria-current="page" to="/school/profile/general">Basic information</Link>
        </li>
        <li className="nav-item">
            <Link className={splitLocation[3] === "account" ? "nav-link active" : "nav-link profile-link"} to="/school/profile/account">Account details</Link>
        </li>   
    </ul>
    )
}

export default ProfileNav
