import axiosInstance from "../config/axiosInstance"
import { requestLoading } from "./requestLoading"
import {toast} from "react-toastify";
import {basicProfileURL, familyProfileURL, learnersFamilyProfileURL, medicalProfileURL, medicaDetailslURL,emergencyContactsURL, emergencyProfileURL} from "../config/constants"
import { getProfileSuccessful,getFamilyProfile, addFamilyMemberSuccessful, getMedicalDetails, getLearnerEmergencyContacts } from "./actionCreators"
import customToast from "../utils/customToast"

export const updateBasicInfo=(basicInfo)=>dispatch=>{
    dispatch(requestLoading)
    axiosInstance.put(basicProfileURL, basicInfo).then(response=>{
        dispatch(requestLoading(false))
        toast.success(response.data.message)
    }).catch(error=>{
        toast.success(error.response.data.message)

    })
}

export const getBasicInfo=()=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.get(basicProfileURL).then(response=>{
        dispatch(requestLoading(false))

        console.log('------>>>**', response)
        dispatch(getProfileSuccessful(response.data))
    }).catch(error=>{
        dispatch(requestLoading(false))
    })
}

export const getFamilyInfo=()=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.get(learnersFamilyProfileURL).then(response=>{
        dispatch(requestLoading(false))
        dispatch(getFamilyProfile(response.data))
    }).catch(error=>{
        dispatch(requestLoading(false))
    })
}

export const addFamilyMember= (familyMemberData)=>dispatch=>{
    dispatch(requestLoading(false))
    axiosInstance.post(familyProfileURL, familyMemberData).then(response=>{
        dispatch(requestLoading(false))
        dispatch(addFamilyMemberSuccessful(true))
        toast.success(response.data.message)
        dispatch(getFamilyInfo())    
    }).catch(error=>{
        toast.error(error.response.data.message)
    })
}

export const addMedicalDetails=medicalDetails=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.post(medicaDetailslURL, medicalDetails).then(response=>{
        dispatch(requestLoading(false))
        toast.success("Medical details added successfully")
        dispatch(getMedicalInfo())
    }).catch(error=>{
        dispatch(requestLoading(false))
        toast.error(error.response.data.message)
    })
}

export const getMedicalInfo=()=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.get(medicalProfileURL).then(response=>{
        dispatch(requestLoading(false))
        dispatch(getMedicalDetails(response.data.medical_details))
    }).catch(error=>{
        dispatch(requestLoading(false))
    })
}

export const addEmergencyContacts=emergencyContact=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.post(emergencyContactsURL, emergencyContact).then(response=>{
        dispatch(requestLoading(false))
        customToast.success("Emergency contact added successfully")
        dispatch(getMedicalInfo())
    }).catch(error=>{
        dispatch(requestLoading(false))
        customToast.error(error.response.data.message)
    })
}

export const getEmergencyContacts=()=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.get(emergencyProfileURL).then(response=>{
        dispatch(requestLoading(false))
        dispatch(getLearnerEmergencyContacts(response.data.emergency_contacts))
        console.log(response.data.emergency_contacts)
    }).catch(error=>{
        dispatch(requestLoading(false))
        console.log(error.response)
    })
}