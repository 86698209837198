import { 
    SCHOOLS_FETCH_SUCCESSFUL,
    SCHOOL_CLASSES_FETCH_SUCCESSFUL,
    GET_APPLICATION_FEE_SUCCESSFUL,
    ADD_CLASS_SUCCESSFUL,
    GET_TOTAL_APPLICATIONS,
    GET_TOTAL_REJECTIONS,
    GET_TOTAL_ADMISSIONS,
    GET_SCHOOL_ADMISSIONS_SUCCESSFUL,
    GET_ACCOUNT_DETAILS,
    GET_SCHOOL_DETAILS, 
    GET_SCHOOL_BALANCE_SUCCESSFUL
} from "../actions/types";

const initialState = {
    schools: [],
    classes: [],
    admission_fee: null,
    totalApplications: '0',
    totalRejections: '0',
    totalAdmissions: '0',
    balance:'0',
    applications: [],
    admissionList: [],
    bankName: '',
    accountName: '',
    accountNumber: '',
    branchName: '',
    swiftCode: '',
    physicalAddress: '',
    primaryContact: '',
    officeContact: '',
    website: '',
    admittingStatus: '',
    admissionFee: ''
}

const schoolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SCHOOLS_FETCH_SUCCESSFUL:
            return {
                ...state,
                schools: action.schoolsPayload
            }
        case SCHOOL_CLASSES_FETCH_SUCCESSFUL:
            return {
                ...state,
                classes: action.classPayload
            }
        case ADD_CLASS_SUCCESSFUL:
            return {
                ...state,
                classes: [...state.classes, action.classPayload]
            }
        case GET_APPLICATION_FEE_SUCCESSFUL:
            return {
                ...state,
                admission_fee: action.admission_fee
            }
        case GET_TOTAL_APPLICATIONS:
            return {
                ...state,
                totalApplications: action.totalApplications
            }
        case GET_TOTAL_REJECTIONS:
            return {
                ...state,
                totalRejections: action.totalRejections
            }
        case GET_TOTAL_ADMISSIONS:
            return {
                ...state,
                totalAdmissions: action.totalAdmissions
            }
        case GET_SCHOOL_ADMISSIONS_SUCCESSFUL:
            return {
                ...state,
                admissionList: action.admissionList
            }
        case GET_SCHOOL_BALANCE_SUCCESSFUL:
                return {
                    ...state,
                    balance: action.payload
                }
        case GET_ACCOUNT_DETAILS:
            return {
                ...state,
                bankName: action.payload.bankName,
                accountName: action.payload.accountName,
                accountNumber: action.payload.accountNumber,
                branchName: action.payload.branchName,
                swiftCode: action.payload.swiftCode
            }
            case GET_SCHOOL_DETAILS:
                return {
                    ...state,
                    physicalAddress: action.payload.physicalAddress,
                    primaryContact: action.payload.primaryContact,
                    officeContact: action.payload.officeContact,
                    website: action.payload.website,
                    admittingStatus: action.payload.admittingStatus,
                    admissionFee: action.payload.admissionFee
                };
        default:
            return state;
    }
}

export default schoolsReducer;
