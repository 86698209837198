import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import brandLogoWhite from "../../static/images/brandLogoWhite.png";
import { currentUser } from "../../config/constants";

const SchoolSideBar = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    // Utility function to check if the current path matches
    const isActive = (path) => splitLocation[1] === path ? "nav-link active" : "nav-link";

    return (
        <Fragment>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <div className="brand-link">
                    <img src={brandLogoWhite} alt="Mukisa LMS Logo" className="brand-image" style={{ opacity: 0.8 }} />
                    <span className="brand-text font-weight-light"> &nbsp;</span>
                </div>

                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="info">
                            <h5 className="company-name">{currentUser}</h5>
                        </div>
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <Link to="/school-portal" className={isActive("school-portal")}>
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>School Dashboard</p>
                                </Link>
                            </li>
                            <li className="nav-item menu-open">
                                <Link to="#" className="nav-link">
                                    <i className="nav-icon fa fa-school"></i>
                                    <p>
                                        Learner Applications
                                        <i className="right fas fa-angle-left"></i>
                                    </p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="/school/application" className="nav-link">
                                            <i className="nav-icon fa fa-child"></i>
                                            <p>
                                                Applications
                                                <span className="right badge badge-danger">New</span>
                                            </p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/school/admissions" className="nav-link">
                                            <i className="nav-icon fas fa-school"></i>
                                            <p>
                                                View Admissions
                                                <span className="right badge badge-danger">New</span>
                                            </p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/classes" className={isActive("classes")}>
                                    <i className="nav-icon far fa-image"></i>
                                    <p>Classes & Courses</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="info">
                        </div>
                    </div>
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <Link to="/school/profile/general" className={isActive("settings")}>
                                    <i className="nav-icon fas fa-cog"></i>
                                    <p>Settings</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/logout" className={isActive("logout")}>
                                    <i className="nav-icon fas fa-sign-out-alt"></i>
                                    <p>Logout</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </Fragment>
    );
};

export default SchoolSideBar;
