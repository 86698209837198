// BarChart.js
import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register required components
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  // Monthly labels for all months
  const labels = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Data grouped by year
  const years = ["2022", "2023", "2024"];
  const applicationsData = {
    "2022": [120, 150, 200, 300, 250, 400, 350, 450, 300, 200, 250, 300],
    "2023": [130, 160, 210, 310, 260, 410, 360, 460, 310, 210, 260, 310],
    "2024": [140, 170, 220, 320, 270, 420, 370, 470, 320, 220, 270, 320]
  };

  const admissionsData = {
    "2022": [100, 120, 150, 200, 180, 300, 250, 300, 200, 150, 180, 220],
    "2023": [110, 130, 160, 210, 190, 310, 260, 310, 210, 160, 190, 230],
    "2024": [120, 140, 170, 220, 200, 320, 270, 320, 220, 170, 200, 240]
  };

  const rejectionsData = {
    "2022": [20, 30, 50, 80, 70, 100, 90, 100, 70, 50, 70, 80],
    "2023": [20, 30, 40, 70, 60, 90, 80, 90, 60, 40, 60, 80],
    "2024": [20, 40, 60, 80, 70, 100, 90, 100, 70, 50, 70, 90]
  };

  // Prepare datasets for chart
  const barChartData = {
    labels: labels,
    datasets: [
      {
        label: "Applications Submitted",
        data: [].concat(...years.map(year => applicationsData[year])),
        backgroundColor: "rgba(0, 123, 255, 0.6)",
        borderColor: "#007bff",
        borderWidth: 1,
        fill: true,
      },
      {
        label: "Admissions",
        data: [].concat(...years.map(year => admissionsData[year])),
        backgroundColor: "rgba(40, 167, 69, 0.6)",
        borderColor: "#28a745",
        borderWidth: 1,
        fill: true,
      },
      {
        label: "Rejections",
        data: [].concat(...years.map(year => rejectionsData[year])),
        backgroundColor: "rgba(220, 53, 69, 0.6)",
        borderColor: "#dc3545",
        borderWidth: 1,
        fill: true,
      }
    ],
  };

  return (
    <div style={{ marginBottom: "40px" }}>
      <h2>Monthly Applications, Admissions, and Rejections</h2>
      <Bar
        data={barChartData}
        options={{
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: "School Applications Analytics Over Time",
            },
            legend: {
              display: true,
              position: "top",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Number of Applications",
              },
            },
            x: {
              title: {
                display: true,
                text: "Months",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default BarChart;
