import axiosInstance from "../config/axiosInstance"
import { requestLoading } from "./requestLoading"
import {addInstitution} from "../config/constants"
import { 
    kindergatensURL, classURL, schoolClassURL,
    totalApplicationsURL, totalRejectionsURL, 
    totalAdmissionsURL, schoolAccountURL, schoolInfoURL, schoolBalanceURL
} from "../config/constants"
import {toast} from "react-toastify";
import { 
    getSchoolsSuccessful,
    getClassesSuccessful,
    totalApplications, 
    totalRejections,
    totalAdmissions,
    getAccountDetails,
    getSchoolDetails,
    getSchoolBalance
} from "./actionCreators"


export const registerInstitution =(institutionData, mediaFile)=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.post(addInstitution, institutionData).then(response=>{
        dispatch(requestLoading(false))
        toast.success(response.data.message)
    }).catch(error=>{
        dispatch(requestLoading(false))
        toast.error(error.response.data.message)
    })
}

export const addClass=classData=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.post(classURL, classData).then(response=>{
        dispatch(requestLoading(false))
        const transformed = response.data.classes.map(item => [
            item.id,
            item.name
        ]);
        dispatch(getClassesSuccessful(transformed));
        toast.success(response.data.message)
    }).catch(error=>{
        dispatch(requestLoading(false))
        toast.error(error.response.data.message) 
    })    
}

export const getSchools=(school_type)=> dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.get(kindergatensURL+'/'+school_type).then(response=>{
        dispatch(requestLoading(false))
        const selectOptions = prepOptions(response.data)
        dispatch(getSchoolsSuccessful(selectOptions))
    }).catch(error=>{
        dispatch(requestLoading(false))
        toast.error(error.response.data.message)
    })
}

export const getClasses=(selectedSchool)=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.get(classURL+'/'+selectedSchool).then(response=>{
        dispatch(requestLoading(false))
        const classOptions = prepOptions(response.data)
        dispatch(getClassesSuccessful(classOptions))
    }).catch(error=>{
        dispatch(requestLoading(false))
    })
}

export const getSchoolClasses = () => dispatch => {
    dispatch(requestLoading(true));

    axiosInstance.get(schoolClassURL)
        .then(response => {
            dispatch(requestLoading(false));
            const transformed = response.data.map(item => [
                item.id,
                item.name
            ]);

            dispatch(getClassesSuccessful(transformed));
        })
        .catch(error => {
            dispatch(requestLoading(false));
            console.error("Error fetching school classes:", error);
        });
};

export const getTotalApplications = () => dispatch => {
    axiosInstance.get(totalApplicationsURL).then(response =>{
        dispatch(totalApplications(response?.data?.total_applications));
    }).catch( error =>{
        dispatch(requestLoading(false))

    })
}

export const getTotalAdmissions = () => dispatch => {
    axiosInstance.get(totalAdmissionsURL).then(response =>{
        dispatch(totalAdmissions(response?.data?.total_admissions));
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}

export const getTotalDeclined = () => dispatch => {
    axiosInstance.get(totalRejectionsURL).then(response =>{
        dispatch(totalRejections(response?.data?.total_rejections));
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}

export const getSchoolAccountDetails = () => dispatch => {
    axiosInstance.get(schoolAccountURL).then(response =>{
        console.log(response)
        dispatch(getAccountDetails(response?.data));
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}

export const getSchoolBasicInfo  = () => dispatch => {
    axiosInstance.get(schoolInfoURL).then(response =>{
        dispatch(getSchoolDetails(response?.data));
        toast.success(response?.data.message);
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}

export const addSchoolAccount = (accountDetails) => dispatch => {
    dispatch(requestLoading(true))

    axiosInstance.post(schoolAccountURL, accountDetails).then(response=>{
        dispatch(requestLoading(false))
        dispatch(getAccountDetails(response?.data));
        toast.success(response?.data.message);
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}

export const addSchoolBasicInfo = (accountDetails) => dispatch => {
    dispatch(requestLoading(true))

    axiosInstance.post(schoolInfoURL, accountDetails).then(response=>{
        dispatch(requestLoading(false))
        dispatch(getAccountDetails(response?.data));
        toast.success(response?.data.message);
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}

export const addSchoolInfo = (schoolDetails) => dispatch => {
    axiosInstance.post(classURL, schoolDetails).then(response=>{
        console.log(response)
        // dispatch(getAccountDetails(response?.data?.total_rejections));
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}

export const getSchoolInfo = (schoolInfoURL) => dispatch => {
    axiosInstance.get(schoolInfoURL).then(response=>{
        console.log(response)
        // dispatch(getAccountDetails(response?.data?.total_rejections));
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}

export const getBalance = () => dispatch => {
    axiosInstance.get(schoolBalanceURL).then(response=>{
        console.log(response.data)
        dispatch(getSchoolBalance(response?.data?.balance));
    }).catch( error =>{
        dispatch(requestLoading(false))
    })
}


const prepOptions=(rawData)=>{
    const options =[]
    for(let count=0; count<rawData.length; count++){
        const label = rawData[count].name
        const value = rawData[count].id
        const option = {label, value}
        options.push(option)
    }
    return options
}
