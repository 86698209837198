export const loginURL ='auth/login'
export const userTypeURL ='usertype'
export const register_learner ='learners'
export const addInstitution = 'schools'
export const baseURL = process.env.REACT_APP_BASE_URL
export const timeout='60000'
export const classURL="classes"
export const schoolClassURL = "school/classes"
export const kindergatensURL='schooltype'
export const applicationURL = 'learner/applications'
export const admissionFeeURL = 'admissionfee'
export const learnerApplicationsURL = 'learner/applications'
export const schoolApplicationsURL = 'school/application'
export const basicProfileURL = 'profile/general'
export const familyProfileURL = 'family_members'
export const medicalProfileURL = 'profile/learner_medical_details'
export const emergencyProfileURL = 'profile/emergency_contacts'
export const learnersFamilyProfileURL = 'profile/family/members'
export const medicaDetailslURL = 'medical_details'
export const emergencyContactsURL = 'emergency_contacts'
export const totalApplicationsURL = 'totalapplications'
export const totalRejectionsURL = 'totalarejections'
export const totalAdmissionsURL = 'totaladmissions'
export const admissionsURL = 'admissions'
export const schoolAdmissionsURL = 'school/admissions'
export const leanerAdmissionsURL = 'learner/admissions'
export const schoolAccountURL = 'school/account'
export const schoolInfoURL = 'school/basic-info'
export const schoolBalanceURL = 'school/accounts/balance'

export const token = localStorage.getItem('auth_token')
export const user_type = localStorage.getItem('user_type')
export const currentUser = localStorage.getItem('currentUser')

export const config = {
    bucketName: process.env.REACT_APP_BUCKET,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEY,
    secretAccessKey: process.env.REACT_APP_SECRETKEY,
}
export const customStyle={theme:'primary75'}
export const defaultColumns = ['Name','Position','Office','Age','Start date','Salary']
export const defaultMedicalDetailsColumns = ['Type of illness','Allergies','Physician','Physician Contact','Physician address','Physician Postal Address']
export const defaultInstituionColumns = ['Type', 'Institution name','Telephone number','Email address','Adimission fees']
export const defaultMyapplicationColumns = ['Application Date','Institution name','Class & Course','School Email']
export const defaultSchoolapplicationsColumns = ['Application Date','Learner\'s name','Class / Course','Learner report']
export const defaultLeanerAdmissionColumns = ['Application Date','Institution','Program','Status']


export const defaultEmergencyColumns = ['Contact Person','Telephone number','Address','Relationship']
export const defaultFamilyColumns = ['Relationship','First name','Last name','Occupation','Email','Office contact','Mobile Contact', 'Address','Coresponsent?']
export const defaultClassColumns = ['Class / Course name']
