import React, {Component} from 'react'
import { connect } from 'react-redux';
import DashboardPanel from "../../containers/Dashboard";
import { getBasicInfo } from "../../actions/profileActions"


class Dashboard extends Component{

    constructor(props){
        super(props)
        this.state={
            currentUser:'',
            first_name: '',
            last_name: '',
            phone_number: ''
        }
    }

    componentDidMount(){
        const {getBasicInfo}= this.props
        getBasicInfo()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.currentUser !== prevState.currentUser ||
            nextProps.first_name !== prevState.first_name ||
            nextProps.email !== prevState.email ||
            nextProps.phone_number !== prevState.phone_number ||           
            nextProps.last_name !== prevState.last_name) {

            const userData = {
                name: `${nextProps.first_name} ${nextProps.last_name}`,
                phoneNumber: nextProps.phone_number,
                email: nextProps.email
            };
            localStorage.setItem('userData', JSON.stringify(userData));

            return {
                currentUser: nextProps.currentUser,
            };
        }
        return null;
    }


    render() {
        return(
            <DashboardPanel currentUser={this.state.currentUser}/>
        )
    }
}

const mapStateToProps =state=>({
    ...state.user
})

const DashContent = connect(mapStateToProps,{getBasicInfo})(Dashboard)

export default DashContent
