import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeApplication } from '../../actions/applicationActions';
import ApplicationForm from '../../containers/ApplicationForm';
import PaymentComponent from './PaymentComponent';
import {toast} from "react-toastify";


class Application extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: [],
            reportFile: null,
            admissionFee: 0,
            paymentConfig: null,
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            schoolIdError: '',
            schoolClassIdError: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.classes !== this.props.classes) {
            this.setState({ 
                classes: this.props.classes, 
                admissionFee: this.props.admissionFee 
            });
        }
        if (prevProps.first_name !== this.props.first_name) {
            this.setState({ 
                first_name: this.props.first_name 
            });
        }
    }

    handlePaymentSuccess = (data) => {
        const { makeApplication } = this.props;
        const { reportFile } = this.state;
        const applicationData = {
            school_id: localStorage.getItem('school_id'),
            school_class_id: localStorage.getItem('school_class_id'),
            transactionStatus: data?.status,
            transactionId: data?.transaction_id,
            transactionRef: data?.tx_ref,
            transactionDate: data?.created_at,
            amount: data?.amount
        };

        makeApplication(applicationData, reportFile);
    };

    handlePaymentError = (error) => {
        console.error('Payment failed:', error);
        alert('Payment failed, please try again.');
    };

    handleSubmit = (event) => {
        event.preventDefault();
        
        const schoolId = localStorage.getItem('school_id');
        const schoolClassId = localStorage.getItem('school_class_id');
    
        if (!schoolId || !schoolClassId) {
            if (!schoolId) {
                toast.error('Please select a school.');
            }
            if (!schoolClassId) {
                toast.error('Please select a class.');
            }
            return;
        }
    
        const storedData = JSON.parse(localStorage.getItem('userData'));
        const admissionFee = localStorage.getItem('admissionFee');
        const paymentConfig = {
            public_key: process.env.REACT_APP_PUBLIC_KEY,
            tx_ref: Date.now(),
            amount: admissionFee,
            currency: 'UGX',
            payment_options: 'card, mobilemoney, ussd',
            customer: {
                email: storedData?.email,
                phone_number: storedData?.phoneNumber,
                name: storedData?.name,
            },
            customizations: {
                title: 'School Admissions',
                description: 'Payment for school admission application',
                logo: 'https://easchooladmin.com/static/media/brandLogo.89f3ff98.png',
            },
            callback: this.handlePaymentSuccess,
            onclose: this.handlePaymentError,
        };
    
        this.setState({ paymentConfig });
    };
    

    handleMediaChange = (reportFile) => {
        this.setState({ reportFile });
    };

    render() {
        return (
            <div>
                <ApplicationForm
                    classes={this.state.classes}
                    handleSubmit={this.handleSubmit}
                    handleMediaChange={this.handleMediaChange}
                    admissionFee={this.state.admissionFee}
                    schoolIdError={this.state.schoolIdError}
                    schoolClassIdError={this.state.schoolClassIdError}
                />
                {this.state.paymentConfig && (
                    <PaymentComponent
                        config={this.state.paymentConfig}
                        onSuccess={this.handlePaymentSuccess}
                        onError={this.handlePaymentError}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.schools,
    ...state.user
});

const schoolApplication = connect(mapStateToProps, { makeApplication })(Application);
export default schoolApplication;
