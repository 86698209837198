import { Component } from "react";
import { connect } from "react-redux";
import { getSchoolBasicInfo, addSchoolBasicInfo } from "../../../actions/institutionActions";
import GeneralSettingsContainer from "../../../containers/SchoolSettings/GeneralSettings";
import { toast } from "react-toastify";

class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            physicalAddress: '',
            primaryContact: '',
            officeContact: '',
            website: '',
            admittingStatus: '',
            admissionFee: '',
            errors: {}
        };
    }

    componentDidMount() {
        const { getSchoolBasicInfo } = this.props; // Updated method
        getSchoolBasicInfo(); // Fetch school basic info on mount
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { schoolBasicInfo } = nextProps;
    
        // Check if props have changed and if state is still in its initial state
        if (
            schoolBasicInfo &&
            (schoolBasicInfo.physicalAddress !== prevState.physicalAddress ||
             schoolBasicInfo.primaryContact !== prevState.primaryContact ||
             schoolBasicInfo.officeContact !== prevState.officeContact ||
             schoolBasicInfo.website !== prevState.website ||
             schoolBasicInfo.admittingStatus !== prevState.admittingStatus ||
             schoolBasicInfo.admissionFee !== prevState.admissionFee)
        ) {
            return {
                // Only update state if fields are empty, indicating first load
                physicalAddress: prevState.physicalAddress || schoolBasicInfo.physicalAddress || '',
                primaryContact: prevState.primaryContact || schoolBasicInfo.primaryContact || '',
                officeContact: prevState.officeContact || schoolBasicInfo.officeContact || '',
                website: prevState.website || schoolBasicInfo.website || '',
                admittingStatus: prevState.admittingStatus || schoolBasicInfo.admittingStatus || '',
                admissionFee: prevState.admissionFee || schoolBasicInfo.admissionFee || '',
                errors: {} // Clear errors when loading new data
            };
        }
        return null; // No change to state
    }
    

    validateFields = () => {
        const { physicalAddress, primaryContact, officeContact, website, admittingStatus, admissionFee } = this.state;
        const errors = {};

        if (!physicalAddress) errors.physicalAddress = "Physical address is required.";
        if (!primaryContact) errors.primaryContact = "Primary contact is required.";
        if (!officeContact) errors.officeContact = "Office contact is required.";
        if (!website) errors.website = "Website is required. If you don't have one, put (N/A)";
        if (!admittingStatus) errors.admittingStatus = "Are you open for admissions?";
        if (!admittingStatus || admittingStatus.trim() === "") {  // Check for empty or whitespace
            errors.admittingStatus = "Are you open for admissions? Please select an option.";
        }

        const admissionFeeValue = Number(admissionFee);
        if (!admissionFee || isNaN(admissionFeeValue)) {
            errors.admissionFee = "Admission fee is required.";
        } else if (admissionFeeValue <= 0) {
            errors.admissionFee = "Admission fee should be greater than 0.";
        }

        return errors;
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            [name]: value,
            errors: { ...prevState.errors, [name]: '' }
        }));
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const errors = this.validateFields();
    
        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            toast.error("Please fill in all required fields.");
        } else {
            const { addSchoolBasicInfo } = this.props;
    
            const {
                physicalAddress,
                primaryContact,
                officeContact,
                website,
                admittingStatus,
                admissionFee
            } = this.state;
    
            const schoolData = {
                school_basic_info: {
                    physical_address: physicalAddress,
                    primary_contact: primaryContact,
                    office_contact: officeContact,
                    website: website,
                    admitting_status: admittingStatus
                },
                admission_fee: admissionFee
            };
    
            addSchoolBasicInfo(schoolData);
        }
    };
    

    render() {
        return (
            <GeneralSettingsContainer
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                accountDetails={this.state}
                errors={this.state.errors}
            />
        );
    }
}

const mapStateToProps = state => ({
    schoolBasicInfo: state.schools
});

export default connect(mapStateToProps, { getSchoolBasicInfo, addSchoolBasicInfo })(GeneralSettings);
