import React from "react";
import {Route, Switch} from 'react-router-dom'
import {PrivateRoute} from "../PrivateRoute";
import Login from "../Login";
import Register from "../Register"
import Dashboard from "../Dashboard";
import AdminDashboard from "../Dashboard/AdminDashboard";
import SchoolDashboard from "../Dashboard/SchoolDashboard";
import Institution from "../Institution";
import Logout from "../Logout";
import {SchoolRoute} from "../PrivateRoute/SchoolRoute"
import Classes from "../Courses";
import Application from "../Application";
import MyApplications from "../MyApplications";
import Profile from "../Profile";
import UserProfile from "../Profile";
import FamilyProfile from "../Profile/Family";
import MedicalProfile from "../Profile/medical";
import EmergencyContacts from "../Profile/emergencyContacts";
import RegisterSchool from "../Register/RegisterSchool";
import SchoolApplications from '../Schools/SchoolApplications'
import SchoolAdmissions from '../Schools/SchoolAdmissions'
import LearnerAdmissions from '../LearnerAdmissions'
import schoolSettings from "../Schools/SchoolSettings";
import GeneralSettings from "../Schools/SchoolSettings/GeneralSettings"


export default ()=>(
    <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/register/institution" component={RegisterSchool} />
        <PrivateRoute exact path="/home" component={Dashboard} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/profile/family" component={FamilyProfile} />
        <PrivateRoute exact path="/profile/medical" component={MedicalProfile} />
        <PrivateRoute exact path="/profile/emergencycontacts" component={EmergencyContacts} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact key="kindergarten" path="/application/kindergarten" component={Application} />
        <PrivateRoute exact key ="primary" path="/application/primary" component={Application} />
        <PrivateRoute exact key="secondary" path="/application/secondary" component={Application} />
        <PrivateRoute exact key="tertiary" path="/application/tertiary" component={Application} />
        <PrivateRoute exact key="university" path="/application/university" component={Application} />
        <PrivateRoute exact path="/myapplications" component={MyApplications} />
        <PrivateRoute exact path="/myadmissions" component={LearnerAdmissions} />

        <PrivateRoute exact path="/profile/general" component={UserProfile} />
        <PrivateRoute exact path="/logout" component={Logout} />
        <PrivateRoute exact path="/institution" component={Institution} />
        <PrivateRoute  exact path="/admin-portal" component={AdminDashboard} />
        <SchoolRoute exact path="/school-portal" component={SchoolDashboard} />
        <SchoolRoute exact path="/classes" component={Classes} />
        <SchoolRoute exact path="/school/application" component={SchoolApplications} />
        <SchoolRoute exact path="/school/admissions" component={SchoolAdmissions} />
        <SchoolRoute exact path="/school/profile/account" component={schoolSettings} />
        <SchoolRoute exact path="/school/profile/general" component={GeneralSettings} />



    </Switch>
)
