import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  Legend
);

const SchLineChart = () => {
  // Fluctuating historical data for 2022 and 2023
  const historicalData = {
    2022: [120, 150, 200, 250, 300, 350, 400, 300, 500, 550, 600, 450],
    2023: [130, 160, 140, 260, 310, 360, 410, 460, 510, 560, 500, 660],
  };

  // Calculate the 3-month moving average
  const calculateMovingAverage = (data) => {
    const movingAvg = [];
    for (let i = 0; i < data.length; i++) {
      if (i < 2) {
        movingAvg.push(null); // Not enough data for the first two months
      } else {
        const avg = (data[i] + data[i - 1] + data[i - 2]) / 3; // Average of the last three months
        movingAvg.push(avg);
      }
    }
    return movingAvg;
  };

  const movingAvg2022 = calculateMovingAverage(historicalData[2022]);
  const movingAvg2023 = calculateMovingAverage(historicalData[2023]);

  // Calculate projected data for 2024 based on linear regression
  const simpleLinearRegression = (data2022, data2023) => {
    const combinedData = [...data2022, ...data2023];
    const n = combinedData.length;
    const xValues = Array.from({ length: n }, (_, i) => i + 1); // [1, 2, ..., n]
    const xSum = xValues.reduce((a, b) => a + b, 0);
    const ySum = combinedData.reduce((a, b) => a + b, 0);
    const xySum = xValues.reduce((sum, x, i) => sum + x * combinedData[i], 0);
    const xSquaredSum = xValues.reduce((sum, x) => sum + x * x, 0);

    const m = (n * xySum - xSum * ySum) / (n * xSquaredSum - xSum * xSum);
    const b = (ySum - m * xSum) / n;

    // Projecting for the next year (2024)
    const projectedData = [];
    for (let i = n + 1; i <= n + 12; i++) {
      projectedData.push(m * i + b);
    }
    return projectedData;
  };

  const projectedData2024 = simpleLinearRegression(historicalData[2022], historicalData[2023]);

  // Prepare the chart data
  const lineChartData = {
    labels: [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
    ],
    datasets: [
      {
        label: "2022 Applications",
        data: historicalData[2022],
        borderColor: "#007bff",
        fill: false,
        tension: 0.1,
      },
      {
        label: "2023 Applications",
        data: historicalData[2023],
        borderColor: "#28a745",
        fill: false,
        tension: 0.1,
      },
      {
        label: "2022 Moving Average (3-month)",
        data: movingAvg2022,
        borderColor: "#ff6600",
        fill: false,
        tension: 0.1,
        borderDash: [5, 5], // Dotted line for moving average
      },
      {
        label: "2023 Moving Average (3-month)",
        data: movingAvg2023,
        borderColor: "#ffc107",
        fill: false,
        tension: 0.1,
        borderDash: [5, 5], // Dotted line for moving average
      },
      {
        label: "2024 Applications (Projected)",
        data: projectedData2024,
        borderColor: "#6f42c1",
        fill: false,
        tension: 0.1,
        borderDash: [5, 5], // Dotted line for projections
      },
    ],
  };

  return (
    <div className="chart-container" style={{ position: "relative", width: "100%", height: "400px" }}>
      <Line
        data={lineChartData}
        options={{
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                title: function (tooltipItems) {
                  return tooltipItems[0].label; // Return month as tooltip title
                },
                label: function (context) {
                  return `${context.dataset.label}: ${context.raw.toFixed(0)} applications`; // Return data label and value
                },
                footer: function (tooltipItems) {
                  return `Year: ${new Date().getFullYear()}`; // Add current year as footer
                },
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Number of Applications",
              },
            },
            x: {
              title: {
                display: true,
                text: "Months",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default SchLineChart;
