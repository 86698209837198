import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend, Title);

const SchPieChart = () => {
  const pieChartData = {
    labels: ["Admissions", "Rejections", "Pending"], // Updated labels
    datasets: [{
      data: [60, 30, 10], // Example data: 60% admissions, 30% rejections, 10% pending
      label: "School Admissions",
      backgroundColor: ["#28a745", "#ff6600", "#ffc107"], // Green for admissions, orange for rejections, yellow for pending
      hoverBackgroundColor: ["#175000", "#993d00", "#856404"] // Darker shades for hover effects
    }]
  };

  const pieChart = (
    <Pie
      width={5}
      height={3}
      options={{
        title: {
          display: true,
          text: "Admissions, Rejections, and Pending Applications",
          fontSize: 14
        },
        legend: {
          display: true, // Is the legend shown?
          position: "top" // Position of the legend.
        }
      }}
      data={pieChartData}
    />
  );

  return pieChart;
};

export default SchPieChart;
