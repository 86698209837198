import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Authenticate } from "../PrivateRoute";
import { Redirect } from "react-router-dom";

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        Authenticate.logout(dispatch);
    }, [dispatch]);

    return <Redirect to="/" />;
};

export default Logout;
