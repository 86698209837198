const BasicInfoContainer = (props)=>{
    const {  handleSubmitOnModal, handleChangeOnModal} = props
    return(
        <form onSubmit={handleSubmitOnModal}>

<div className="modal fade" id="basicProfile" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="basicProfileLabel">Update Basic Information</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row mb-2">
        <div className="col-sm-4">
        <label htmlFor="customer">Telephone Number</label>
          <input type="text" name="phone_number" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Telephone number"/>
        </div>

        <div className="col-sm-4">
        <label htmlFor="customer">Gender</label>
        <select type="text" name="gender" onChange={handleChangeOnModal} className="form-control millman-input" placeholder="Select gender">
              <option>Select your gender</option>

              <option>Male</option>
              <option>Female</option>
        </select>
        </div>

        <div className="col-sm-4">
        <label htmlFor="customer">Date of Birth</label>
          <input type="date" name="dob" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Date of birth"/>
        </div> 

        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Close Panel</button>
        <button type="submit" className="btn btn-success">Save changes</button>
      </div>
    </div>
  </div></div>
        

    </form>
    )
}
export default BasicInfoContainer