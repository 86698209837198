import React, { Component } from "react";
import { connect } from "react-redux";

class ApplicationFee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admission_fee: null,
            transactionFees: null,
            computedFees: null,
            baseFee: null,
            schadminCharge: null
        };
    }

    componentDidMount() {
        const { admission_fee } = this.props;
        if (admission_fee) {
            this.calculateFees(admission_fee);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.admission_fee !== this.props.admission_fee) {
            this.calculateFees(this.props.admission_fee);
        }
    }

    calculateFees(admission_fee) {
        const baseFee = admission_fee;
        const schadminPercentageFee = Math.round(0.07 * admission_fee * 100) / 100;
        const schadminCharge = schadminPercentageFee + 1000;
        const computedFees = baseFee + schadminCharge;
        const transactionFees = Math.round(0.03 * computedFees * 100) / 100;
        const admissionFeeToBeCharged = baseFee + schadminCharge;

        localStorage.setItem("admissionFee", admissionFeeToBeCharged);

        this.setState({
            admission_fee,
            transactionFees,
            computedFees,
            baseFee,
            schadminCharge
        });
    }

    render() {
        const { admission_fee, transactionFees, baseFee, schadminCharge } = this.state;
        const total = Math.round((baseFee + transactionFees + schadminCharge) * 100) / 100;

        return admission_fee ? (
            <div>
                <div>
                    <div className="payment-info">Application Fees: UGX {baseFee}</div>
                    <div className="payment-info">Transaction Charge: UGX {transactionFees + schadminCharge}</div>
                    <div className="payment-info">Total Fees: UGX {total}</div>
                </div>
                <div>
                    <input type="checkbox" name="consent" required id="consent" style={{ marginRight: '5px' }} />
                    <label htmlFor="consent">
                        I agree to be charged UGX {total} as total fees.
                    </label>
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    admission_fee: state.schools.admission_fee
});

export default connect(mapStateToProps)(ApplicationFee);
