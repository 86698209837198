import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  Tooltip,
  Legend
);

const LearnerBarChat = () => {
  const chartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    datasets: [
      {
        type: 'bar',
        label: "Applications",
        data: [500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600],
        backgroundColor: "rgba(0, 123, 255, 0.6)",
        borderColor: "#007bff",
        borderWidth: 1,
      },
      {
        type: 'bar',
        label: "Admissions",
        data: [300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1100, 1200],
        backgroundColor: "rgba(40, 167, 69, 0.6)",
        borderColor: "#28a745",
        borderWidth: 1,
      },
      {
        type: 'bar',
        label: "Rejections",
        data: [200, 250, 300, 350, 400, 400, 500, 600, 700, 800, 900, 1000],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "#ff6384",
        borderWidth: 1,
      },
      {
        type: 'line',
        label: "Trend of Admissions",
        data: [300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1100, 1200],
        fill: false,
        borderColor: "rgba(255, 206, 86, 1)",
        tension: 0.1, // Adjust this for line smoothness
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Students',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'School Applications, Admissions, and Rejections',
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default LearnerBarChat;
