import { Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar/schoolSideBar";
import ProfileNav from "./ProfileNav";

const GeneralSettings = (props) => {
    const { handleChange, handleSubmit, accountDetails, errors } = props;

    return (
        <Fragment>
            <div className="wrapper sidebar-mini layout-fixed">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Settings</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                        <li className="breadcrumb-item active">settings</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <ProfileNav />
                            </div>
                            <div className="row mb-2"></div>
                            <div className="row mb-2">
                                <div className="col-sm-12 card">
                                    <div className="card-header">
                                        <div className="row mb-2">
                                            <div className="col-sm-6 profile-panel-padding">
                                                <h5>General</h5>
                                            </div>
                                            <div className="col-sm-6">
                                                <button 
                                                    className="btn btn-success float-right" 
                                                    onClick={handleSubmit} 
                                                    data-toggle="modal" 
                                                    data-target="#medicalModal">
                                                    Add basic information
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body padding-bottom">
                                        <form>
                                            <div className="row mb-2 padding_top">
                                                <div className="col-sm-4">
                                                    <label htmlFor="physicalAddress">Physical Address</label>
                                                    <input 
                                                        type="text" 
                                                        name="physicalAddress" 
                                                        value={accountDetails?.physicalAddress || ''} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.physicalAddress ? 'is-invalid' : ''}`} 
                                                        placeholder="Physical Address"
                                                    />
                                                    {errors?.physicalAddress && <span className="invalid-feedback">{errors.physicalAddress}</span>}
                                                </div>
                                                <div className="col-sm-4">
                                                    <label htmlFor="primaryContact">Primary Contact Number</label>
                                                    <input 
                                                        type="text" 
                                                        name="primaryContact" 
                                                        value={accountDetails?.primaryContact || ''} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.primaryContact ? 'is-invalid' : ''}`} 
                                                        placeholder="Primary Contact Number"
                                                    />
                                                    {errors?.primaryContact && <span className="invalid-feedback">{errors.primaryContact}</span>}
                                                </div>
                                                <div className="col-sm-4">
                                                    <label htmlFor="officeContact">Admissions Office Contact</label>
                                                    <input 
                                                        type="text" 
                                                        name="officeContact" 
                                                        value={accountDetails?.officeContact || ''} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.officeContact ? 'is-invalid' : ''}`} 
                                                        placeholder="Office Contact"
                                                    />
                                                    {errors?.officeContact && <span className="invalid-feedback">{errors.officeContact}</span>}
                                                </div> 
                                            </div>         
                                            <div className="row mb-2 padding_top">
                                                <div className="col-sm-4">
                                                    <label htmlFor="website">Website</label>
                                                    <input 
                                                        type="text" 
                                                        name="website" 
                                                        value={accountDetails?.website || ''} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.website ? 'is-invalid' : ''}`} 
                                                        placeholder="Website"
                                                    />
                                                    {errors?.website && <span className="invalid-feedback">{errors.website}</span>}
                                                </div> 
                                                <div className="col-sm-4">
                                                    <label htmlFor="admitingStatus">Are you Open for Admission?</label>
                                                    <select 
                                                        name="admittingStatus" 
                                                        value={accountDetails?.admittingStatus} 
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.admittingStatus ? 'is-invalid' : ''}`}
                                                    >
                                                        <option value="">Select status</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                    {errors?.admittingStatus && <span className="invalid-feedback">{errors.admittingStatus}</span>}

                                                </div> 
                                                <div className="col-sm-4">
                                                    <label htmlFor="admissionFee">Admission Fee</label>
                                                    <input 
                                                        type="number" 
                                                        name="admissionFee" 
                                                        value={accountDetails?.admissionFee || ''}  
                                                        onChange={handleChange}  
                                                        className={`form-control millman-input ${errors?.admissionFee ? 'is-invalid' : ''}`} 
                                                        placeholder="Admission Fee"
                                                    />
                                                    {errors?.admissionFee && <span className="invalid-feedback">{errors.admissionFee}</span>}
                                                </div> 
                                            </div>   
                                        </form>       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <Footer />
            </div>    
        </Fragment>
    );
}

export default GeneralSettings;
