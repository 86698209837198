import React from "react";
import { FileUploader } from "react-drag-drop-files";

const MediaUpload = (props) => {
    const { handleMediaChange, fileTypes, mediaLabel } = props;

    const handleChange = (file) => {
        handleMediaChange(file); // Pass the uploaded file back to the parent component
    };

    return (
        <FileUploader
            label={mediaLabel}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes="mediaUploadField"
        />
    );
};

export default MediaUpload;
