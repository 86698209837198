import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { getSchools, getClasses } from "../../actions/institutionActions";
import { getAdmissionFee } from "../../actions/applicationActions";
import { customStyle } from "../../config/constants";

const Schools = ({ getSchools, getClasses, getAdmissionFee, schools, admission_fee }) => {
    const [selectedSchool, setSelectedSchool] = useState(null);

    // Fetch schools based on the school type from the URL when the component mounts
    useEffect(() => {
        const pageLocation = window.location.pathname;
        const schoolType = pageLocation.split('/')[2];
        getSchools(schoolType);
    }, [getSchools]);

    // Handle school selection
    const handleSelectChange = (select) => {
        setSelectedSchool(select.label);
        localStorage.setItem('school_id', select.value);
        getAdmissionFee(select.value);
        getClasses(select.value);
    };

    return (
        <Select
            options={schools}
            styles={customStyle}
            onChange={handleSelectChange}
            value={selectedSchool ? { value: selectedSchool, label: selectedSchool } : null}
        />
    );
};

const mapStateToProps = (state) => ({
    schools: state.schools.schools,
    admission_fee: state.schools.admission_fee,
});

export default connect(mapStateToProps, { getSchools, getClasses, getAdmissionFee })(Schools);
