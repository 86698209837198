import {
    SCHOOLS_FETCH_SUCCESSFUL,
    LOGIN_ERROR,
    LOGIN_INITIATED,
    LOGIN_SUCCESSFUL, 
    LOGOUT_USER, 
    REGISTER_INSTITUTION_INITIATED,
     REGISTRATION_ERROR,
     REGISTRATION_SUCCESSFUL,
     SCHOOL_CLASSES_FETCH_SUCCESSFUL,
     GET_APPLICATION_SUCCESSFUL,
     GET_APPLICATION_FEE_SUCCESSFUL,
     GET_LEARNER_APPLICATIONS_SUCCESSFUL,
     GET_PROFILE_SUCCESSFUL,
     FETCH_FAMILY_DETAILS,
     ADD_FAMILY_MEMEMBER_SUCCESSFUL,
     MEDICALL_DETALS_FETCH_SUCCESSFUL,
     EMERGENCY_CONTACTS_FETCH_SUCCESSFUL,
     GET_TOTAL_APPLICATIONS,
     GET_TOTAL_REJECTIONS,
     GET_TOTAL_ADMISSIONS,
     GET_SCHOOL_ADMISSIONS_SUCCESSFUL,
     GET_ACCOUNT_DETAILS,
     GET_SCHOOL_DETAILS,
     GET_SCHOOL_BALANCE_SUCCESSFUL
} from "./types";

export const loginInitiated=()=>({
    type:LOGIN_INITIATED
})

export const loginSuccessful=(userDetails)=>({
    type:LOGIN_SUCCESSFUL,
    userDetails
})
export const loginError=(payload)=>({
    type:LOGIN_ERROR,
    payload
})
export const logoutUser=()=>({
    type: LOGOUT_USER
})

export const registrationSuccessful=()=>({
    type:REGISTRATION_SUCCESSFUL
})

export const registrationError=()=>({
    type: REGISTRATION_ERROR
})

export const registerInstitution=()=>({
    type:REGISTER_INSTITUTION_INITIATED
})

export const getSchoolsSuccessful=(schoolsPayload)=>({
    type:SCHOOLS_FETCH_SUCCESSFUL,
    schoolsPayload
})

export const getClassesSuccessful=(classPayload)=>({
    type:SCHOOL_CLASSES_FETCH_SUCCESSFUL,
    classPayload
})

export const getApplications=(applicationPayload)=>({
    type: GET_APPLICATION_SUCCESSFUL,
    applicationPayload
})

export const applicationFeeSuccess=(admission_fee)=>({
    type: GET_APPLICATION_FEE_SUCCESSFUL,
    admission_fee
})

export const totalApplications=(totalApplications)=>({
    type: GET_TOTAL_APPLICATIONS,
    totalApplications
})

export const totalRejections=(totalRejections)=>({
    type: GET_TOTAL_REJECTIONS,
    totalRejections
})

export const totalAdmissions=(totalAdmissions)=>({
    type: GET_TOTAL_ADMISSIONS,
    totalAdmissions
})

export const getLearnerApplicationsSuccessful=(applicationsPayload)=>({
    type: GET_LEARNER_APPLICATIONS_SUCCESSFUL,
    applicationsPayload
})
export const getSchoolAdmissionsListSuccessful=(admissionList)=>({
    type: GET_SCHOOL_ADMISSIONS_SUCCESSFUL,
    admissionList
})

export const getProfileSuccessful=(profilePayload)=>({
    type:GET_PROFILE_SUCCESSFUL,
    profilePayload
})

export const getFamilyProfile=(familyPayload)=>({
    type:FETCH_FAMILY_DETAILS,
    familyPayload
})

export const addFamilyMemberSuccessful=(payload)=>({
    type: ADD_FAMILY_MEMEMBER_SUCCESSFUL,
    payload
})

export const getMedicalDetails = (medicaDetails)=>({
    type: MEDICALL_DETALS_FETCH_SUCCESSFUL,
    medicaDetails
})

export const getLearnerEmergencyContacts = (emergencyContacts)=>({
    type: EMERGENCY_CONTACTS_FETCH_SUCCESSFUL,
    emergencyContacts
})

export const getAccountDetails= (payload)=>({
    type: GET_ACCOUNT_DETAILS,
    payload
})

export const getSchoolDetails= (payload)=>({
    type: GET_SCHOOL_DETAILS,
    payload
})

export const getSchoolBalance= (payload)=>({
    type: GET_SCHOOL_BALANCE_SUCCESSFUL,
    payload
})